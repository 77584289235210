// utils to work with COG files to preview on map
// generates "virtual" layers

import { ILayer, LayerType } from '../modules/layers/model';

const IO_TILER_URL = 'https://api.impactobservatory.com/titiler-internal';

export const splitPath = (path: string): { filename: string; directory: string } => {
  const [last, ...rest] = path.split('/').reverse();
  const directory = rest.reverse().join('/');
  const filename = last.replace('.tif', '');
  return { filename, directory };
};

export const PreviewLayer = (path: string): ILayer => {
  // creates a temporary layer object from a tif in blob storage
  const { filename, directory } = splitPath(path);
  const slug = filename.replaceAll('-', '_');
  const name = slug.replaceAll('_', ' ');

  let legend = {};

  const layer = {
    id: path,
    slug,
    name,
    category: [],
    tags: [],
    licenseRestriction: false,
    createdAt: '',
    updatedAt: '',
    version: 0,
    description: '',
    interactionConfig: {},
    legendConfig: legend,
    layerConfig: {},
    published: true,
    source: {
      type: 'raster',
      tiles: [`${IO_TILER_URL}/cog/tiles/{z}/{x}/{y}@2x.png?url=/vsiaz/${path}`],
    },
    type: LayerType.raster,
  };
  return layer;
};
