/*
  Copyright 2018-2020 National Geographic Society

  Use of this software does not constitute endorsement by National Geographic
  Society (NGS). The NGS name and NGS logo may not be used for any purpose without
  written permission from NGS.

  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import withStyles from '@material-ui/core/styles/withStyles';
import axios from 'axios';
import classnames from 'classnames';
import { isNumber, some } from 'lodash';
import debounce from 'lodash/debounce';
import svgToMiniDataURI from 'mini-svg-data-uri';
import React, { useContext } from 'react';
import { renderToString } from 'react-dom/server';
import isEqual from 'react-fast-compare';
import { ScaleControl } from 'react-map-gl';
import Link from 'redux-first-router-link';
import { trackGtagEvent } from 'utils';

import {
  InfoControl,
  Map,
  MapControls,
  RecenterControl,
  Spinner,
  UserMenu,
  ZoomControl,
} from '@marapp/earth-shared';

import { COMPANY_ABOUT_URL, MAP_API_URL, MAP_CDN_URL, MAP_MAPBOX_TOKEN } from '../../config';
import experienceIMG from '../../images/pins/experience-marker.svg';
import { ILayer, ILayerGroup } from '../../modules/layers/model';
import { IPopup } from '../../modules/map/selectors';
import ProfileService from '../../services/ProfileService';
import {
  MAP_CUSTOM_ATTRIBUTION,
  APP_BASEMAPS,
  APP_TERMS_OF_USE,
  MAP_SHOW_DISCLAIMER,
  POWERED_BY_URL,
  POWERED_BY_PC_URL,
} from '../../theme';
import { Auth0Context } from '../../utils/contexts';
import {
  extractCoordinatesFromUrl,
  isValidUrlCoordinateGroup,
  IUrlCoordinates,
  layerIdFromGroup,
} from '../../utils/map';
import CustomMapControls from './controls';
import LayerManager from './layer-manager';
import Legend from './legend';
import MapDisclaimer from './disclaimer';
import Popup from './popup';
import ZoomControlPopup from './zoom-control-popup';

const CUSTOM_IMAGES = [{ id: 'experience-marker', src: experienceIMG }];

const styles = (theme, sidebarOpen) => ({
  root: {
    position: 'relative',
    height: '100%',
    width: '100%',
    zIndex: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& .hidden': {
      display: 'none !important',
      visibility: 'hidden',
    },
    '& .mapboxgl-ctrl-parent': {
      width: '100%',
      height: '100%',
    },
    '& .mapboxgl-ctrl-attrib': {
      '&.mapboxgl-compact': {
        bottom: theme.spacing(16) + 4,
        right: theme.spacing(2) - 1,
        backgroundColor: 'white',
        borderRadius: 4, // match the box
        outlineColor: theme.palette.grey['600'], // use outline color as secondary border
        outlineOffset: -7,
        outlineWidth: 7,
        outlineStyle: 'solid',
        marginLeft: 'auto',
        maxWidth: 400,
        minWidth: 28,
        minHeight: 30,
        fontSize: 'clamp(10px, 1vw, 12px)',
        '&:hover': {
          outlineColor: theme.palette.grey['500'],
        },
        '&.mapboxgl-compact-show': {
          backgroundColor: 'hsla(0,0%,100%,.5)',
          outline: 'none',
          '& .mapboxgl-ctrl-attrib-button': {
            width: 28,
            height: 30,
          },
        },
        '& .mapboxgl-ctrl-attrib-button': {
          top: 'auto',
          backgroundImage: 'none', // remove original icon
          backgroundColor: theme.palette.grey['600'],
          maskImage: `url("${svgToMiniDataURI(renderToString(<InfoControl />))}")`,
          bottom: 0,
          width: '100%',
          height: '100%',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.grey['500'],
          },
        },
      },
    },
    '& .mapboxgl-ctrl-attrib-inner': {
      textAlign: 'right',
      color: theme.palette.grey['800'],
      '&:hover': {
        color: theme.palette.grey['900'],
      },
      // show only our customized map feedback link, not the generic one
      '& a[title="Improve this map"]': {
        display: 'none',
      },
    },
    '& .marapp-qa-io-attribution': {
      position: 'absolute',
      right: 0,
      bottom: 0,
      height: 20,
      width: 'fit-content !important',
      padding: '2px 108px 2px 6px',
      color: theme.palette.grey['800'],
      fontSize: '12px',
      backgroundColor: 'hsla(0,0%,100%,.5)',
      '& a': {
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        '&:hover': {
          borderBottomColor: 'transparent',
        },
      },
    },
    '& .mapboxgl-ctrl-scale': {
      position: 'absolute',
      right: theme.spacing(3),
      bottom: 2,
      fontSize: 11,
      lineHeight: '14px',
      minWidth: 54,
      padding: '0 3px',
      zIndex: 10,
      backgroundColor: 'transparent',
      borderColor: theme.palette.grey['900'],
      color: theme.palette.grey['900'],
    },
  },
  open: {
    width: '100%',
  },
  spinner: {
    display: 'inline-block !important',
    maxWidth: '100%',
    float: 'left',
    position: 'static',
    padding: 0,
    marginRight: 8,
  },
});

interface IMap {
  viewport?: { zoom: any };
  bounds?: {};
  popup: IPopup;
  classes: any;
  className: string;
  layerManagerBounds?: {};
  interactions?: {};
  mapStyle?: string;
  mapLabels?: boolean;
  mapRoads?: boolean;
  mapboxConfig?: {};
  setInitialUrlCoordinates?: (coordinates: IUrlCoordinates) => void;
  setMapViewport?: (data: any) => void;
  setMapInteractions?: (data: any) => void;
  setMapBounds?: (data: any) => void;
  selectedOpen?: boolean;
  showDisclaimer?: boolean;
  setShowDisclaimer?: (data: any) => void;
  t?: (text: string, opt?: any) => string;
  page?: string;
  activeInteractiveLayersIds?: any;
  activeInteractiveLayers?: any[];
  activeInteractiveLayer?: any;
  layerGroups?: ILayerGroup[];
  layerManagerLayers?: any[];
}

interface IMapState {
  initialUrlCoordinates?: IUrlCoordinates;
}

class MapComponent extends React.Component<IMap, IMapState> {
  public static defaultProps = {
    bounds: {},
    mapLabels: true,
    mapRoads: true,
  };
  public onViewportChange = debounce((viewport) => {
    const { setMapViewport } = this.props;
    setMapViewport(viewport);
  }, 250);
  private map: any;

  constructor(props) {
    super(props);
  }

  public componentDidMount() {
    const { setInitialUrlCoordinates } = this.props;
    const initialUrlCoordinates = extractCoordinatesFromUrl();
    this.setState({
      initialUrlCoordinates,
    });
    if (some(initialUrlCoordinates, isNumber)) {
      setInitialUrlCoordinates(initialUrlCoordinates);
    }

    this.map?.on('render', this.handleLoadIndicator)?.on('idle', this.handleLoadIndicator);
  }

  public componentWillUnmount() {
    this.map?.off('render', this.handleLoadIndicator)?.off('idle', this.handleLoadIndicator);
  }

  public componentDidUpdate(prevProps) {
    const { mapLabels, mapRoads, interactions, viewport, setMapViewport } = this.props;
    const {
      mapLabels: prevMapLabels,
      mapRoads: prevMapRoads,
      interactions: prevInteractions,
    } = prevProps;

    if (mapLabels !== prevMapLabels) {
      this.setLabels();
    }

    if (mapRoads !== prevMapRoads) {
      this.setRoads();
    }

    if (!isEqual(interactions, prevInteractions)) {
      Object.keys(interactions).forEach((k) => {
        const { data, geometry } = interactions[k];

        if (data && data.cluster) {
          const { zoom } = viewport;

          this.map.getSource(k).getClusterExpansionZoom(data.cluster_id, (err, newZoom) => {
            if (err) {
              return;
            }
            const { coordinates } = geometry;
            const difference = Math.abs(zoom - newZoom);

            setMapViewport({
              latitude: coordinates[1],
              longitude: coordinates[0],
              zoom: newZoom,
              transitionDuration: 400 + difference * 100,
            });
          });
        }
      });
    }
  }

  public onZoomChange = (zoom) => {
    const { viewport } = this.props;

    this.onViewportChange({
      ...viewport,
      zoom,
      transitionDuration: 500,
    });
  };

  public onRecenterChange = () => {
    const { bounds, setMapBounds } = this.props;

    setMapBounds({});

    requestAnimationFrame(() => {
      setMapBounds(bounds);
    });
  };

  public onStyleLoad = () => {
    this.setLabels();
    this.setRoads();

    // Add custom images
    this.setCustomImages();
  };

  public onReady = ({ map }) => {
    this.map = map;

    // Listeners
    this.map?.on('style.load', this.onStyleLoad);
  };

  public onClick = (e) => {
    const { setMapInteractions } = this.props;

    if (e.features && e.features.length && !e.target.classList.contains('mapbox-prevent-click')) {
      // No better way to do this
      const { features, lngLat } = e;
      setMapInteractions({ features, lngLat });
    } else {
      setMapInteractions({});
    }
  };

  public onTransformRequest = (url, resourceType) => {
    const { layerGroups } = this.props;

    // calculate where the tile would be on the CDN
    // pass this to the fetch in the header X-Cache-Check-CDN
    // and intercept in service worker to actually check
    // because we can't call async code here
    if (resourceType === 'Tile' && url.includes(MAP_API_URL) && MAP_CDN_URL) {
      const tileUrl = new URL(url);
      // get layerId
      const tilePath = tileUrl.pathname.replace('/api/tiles', '');
      const layerId = tilePath.split('/')[1];
      const layer = layerIdFromGroup(layerGroups, layerId);

      if (layer) {
        // transform to CDN path
        let cdnPath;
        if (layer.STACConfig) {
          cdnPath = '/tile_'
            .concat(layer.STACConfig.collection)
            .concat(tileUrl.searchParams.get('v'))
            .concat(layer.STACConfig.asset)
            .concat(layer.STACConfig.startDate)
            .concat(layer.STACConfig.endDate)
            .concat('.png')
            .replace(/-/g, '');
        } else {
          // ?
        }

        if (cdnPath) {
          const cdnUrl = MAP_CDN_URL.concat('/map-tiles').concat(tilePath).concat(cdnPath);
          return {
            url,
            headers: { 'X-Cache-Check-CDN': cdnUrl },
          };
        }
      }
    }

    if (resourceType === 'Source' && url.includes(MAP_API_URL)) {
      return {
        url,
        headers: { Authorization: axios.defaults.headers.common.Authorization },
      };
    }

    return {
      url,
    };
  };

  public setLabels = () => {
    const LABELS_GROUP = ['label', 'labels'];
    const LABELS_IGNORE_GROUP = 'road-labels';
    const SYMBOLS_GROUP = ['symbol'];

    const { mapLabels, mapRoads } = this.props;
    const { layers, metadata } = this.map.getStyle();
    let labelLayers;

    if (metadata && 'mapbox:groups' in metadata) {
      /* Determine label layers from mapbox metadata */
      const groups = Object.keys(metadata['mapbox:groups']).filter((k) => {
        const { name } = metadata['mapbox:groups'][k];
        const labelGroups = LABELS_GROUP.map(
          (lgr) =>
            name.toLowerCase().includes(lgr) && !name.toLowerCase().includes(LABELS_IGNORE_GROUP)
        );
        return labelGroups.some((bool) => bool);
      });

      labelLayers = layers.filter((l) => {
        const { metadata } = l;
        if (!metadata) {
          return false;
        }

        const gr = metadata['mapbox:group'];
        return groups.includes(gr);
      });
    } else {
      /* Determine label layers from source-layer */
      labelLayers = layers.filter((l) => {
        const { 'source-layer': sourceLayer, type } = l;
        if (sourceLayer) {
          const hasLabels = LABELS_GROUP.map(
            (lgr) =>
              sourceLayer.toLowerCase().includes(lgr) &&
              !sourceLayer.toLowerCase().includes(LABELS_IGNORE_GROUP)
          );
          const hasSymbols = SYMBOLS_GROUP.map((sgr) => type.toLowerCase().includes(sgr));
          return hasLabels.concat(hasSymbols).some((bool) => bool);
        }
      });
    }

    labelLayers.forEach((l) => {
      const visibility = mapLabels ? 'visible' : 'none';
      this.map.setLayoutProperty(l.id, 'visibility', visibility);
      this.map.moveLayer(l.id); // set to top of hierarchy
    });

    this.setRoadLabels();
  };

  public setRoads = () => {
    const ROADS_GROUP = ['road', 'roads', 'bridges', 'tunnels', 'surface', 'surface-icons'];
    const ROADS_IGNORE_GROUP = 'road-labels';

    const { mapRoads } = this.props;
    const { layers, metadata } = this.map.getStyle();
    let roadLayers;

    if (metadata && 'mapbox:groups' in metadata) {
      /* Determine road layers from mapbox metadata */
      const groups = Object.keys(metadata['mapbox:groups']).filter((k) => {
        const { name } = metadata['mapbox:groups'][k];
        const roadGroups = ROADS_GROUP.map(
          (rgr) =>
            name.toLowerCase().includes(rgr) && !name.toLowerCase().includes(ROADS_IGNORE_GROUP)
        );
        return roadGroups.some((bool) => bool);
      });

      roadLayers = layers.filter((l) => {
        const { metadata } = l;
        if (!metadata) {
          return false;
        }
        const gr = metadata['mapbox:group'];
        return groups.includes(gr);
      });
    } else {
      /* Determine road layers from source-layer */
      roadLayers = layers.filter((l) => {
        const { 'source-layer': sourceLayer } = l;
        if (sourceLayer) {
          const hasRoads = ROADS_GROUP.map(
            (rgr) =>
              sourceLayer.toLowerCase().includes(rgr) &&
              !sourceLayer.toLowerCase().includes(ROADS_IGNORE_GROUP)
          );
          return hasRoads.some((bool) => bool);
        }
      });
    }

    roadLayers.forEach((l) => {
      const visibility = mapRoads ? 'visible' : 'none';
      this.map.setLayoutProperty(l.id, 'visibility', visibility);
      this.map.moveLayer(l.id); // set to top of hierarchy
      // BUG, these should be BELOW admin labels
    });
    this.setRoadLabels();
  };

  public setRoadLabels = () => {
    const ROAD_LABELS_GROUP = ['road-labels'];

    const { mapLabels, mapRoads } = this.props;
    const { layers, metadata } = this.map.getStyle();
    let roadLabelLayers;

    if (metadata && 'mapbox:groups' in metadata) {
      /* Determine label layers from mapbox metadata */
      const roadLabelGroups = Object.keys(metadata['mapbox:groups']).filter((k) => {
        const { name } = metadata['mapbox:groups'][k];
        const labelGroups = ROAD_LABELS_GROUP.map((lgr) => name.toLowerCase().includes(lgr));

        return labelGroups.some((bool) => bool);
      });

      roadLabelLayers = layers.filter((l) => {
        const { metadata } = l;
        if (!metadata) {
          return false;
        }

        const gr = metadata['mapbox:group'];
        return roadLabelGroups.includes(gr);
      });
    } else {
      /* Determine road label layers from source-layer */
      roadLabelLayers = layers.filter((l) => {
        const { 'source-layer': sourceLayer } = l;
        if (sourceLayer) {
          const hasRoads = ROAD_LABELS_GROUP.map((rgr) =>
            sourceLayer.toLowerCase().includes(ROAD_LABELS_GROUP)
          );
          return hasRoads.some((bool) => bool);
        }
      });
    }

    // if roads are on, set labels visible
    roadLabelLayers.forEach((l) => {
      const visibility = mapRoads && mapLabels ? 'visible' : 'none';
      this.map.setLayoutProperty(l.id, 'visibility', visibility);
      this.map.moveLayer(l.id); // set to top of hierarchy
    });
  };

  public setCustomImages = () => {
    CUSTOM_IMAGES.forEach(({ id, src }) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        this.map.addImage(id, img);
      };
    });
  };

  /**
   * Once the map is loaded, check if there were any valid coordinates provided in the URL.
   * If so, set the viewport in accordance with those coordinates
   */
  public onLoad = () => {
    const { initialUrlCoordinates } = this.state;

    if (isValidUrlCoordinateGroup(initialUrlCoordinates)) {
      this.onViewportChange({
        ...initialUrlCoordinates,
        transitionDuration: 800,
      });

      this.onViewportChange.flush(); // execute directly the last call

      this.map.resize(); // trigger map resize to ensure layers load
    }
  };

  public render() {
    const {
      selectedOpen,
      mapStyle,
      viewport,
      bounds,
      classes,
      className,
      popup,
      mapboxConfig,
      page,
      activeInteractiveLayersIds,
      activeInteractiveLayers,
      activeInteractiveLayer,
      layerGroups,
      layerManagerLayers,
      layerManagerBounds,
      setMapInteractions,
      showDisclaimer,
      setShowDisclaimer,
      t,
    } = this.props;

    // @ts-ignore
    return (
      <div
        className={classnames(classes.root, className, {
          [classes.open]: selectedOpen,
        })}
      >
        <UserMenuWrapper selected={page} />

        <Map
          mapboxApiAccessToken={MAP_MAPBOX_TOKEN}
          // Attributes
          mapStyle={APP_BASEMAPS.find((x) => x.slug === mapStyle)?.id}
          customAttribution={MAP_CUSTOM_ATTRIBUTION}
          viewport={viewport}
          bounds={bounds}
          {...mapboxConfig}
          interactiveLayerIds={activeInteractiveLayersIds}
          // Functions
          onViewportChange={this.onViewportChange}
          onClick={this.onClick}
          onLoad={this.onLoad}
          onReady={this.onReady}
          transformRequest={this.onTransformRequest}
        >
          {(map) => {
            return (
              <div className="mapboxgl-ctrl-parent">
                <Popup
                  popup={popup}
                  setMapInteractions={setMapInteractions}
                  activeInteractiveLayers={activeInteractiveLayers}
                  activeInteractiveLayer={activeInteractiveLayer}
                />

                <LayerManager map={map} layers={layerManagerLayers} bounds={layerManagerBounds} />
                {MAP_SHOW_DISCLAIMER && (
                  <MapDisclaimer show={showDisclaimer} setShow={setShowDisclaimer} />
                )}
                <ZoomControlPopup
                  onZoomChange={this.onZoomChange}
                  layers={layerManagerLayers}
                  viewport={viewport}
                />

                {/* SCALE INDICATOR - needs to have access to the map context */}
                <ScaleControl maxWidth={80} style={{ width: '100%', height: '100%' }} />

                <div className="marapp-qa-io-attribution">
                  {POWERED_BY_PC_URL ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('Powered by (two)', {
                          link1: POWERED_BY_URL,
                          value1: 'Impact Observatory',
                          link2: POWERED_BY_PC_URL,
                          value2: t('Planetary Computer'),
                        }),
                      }}
                    />
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('Powered by', {
                          link: POWERED_BY_URL,
                          value: 'Impact Observatory',
                        }),
                      }}
                    />
                  )}
                  {' | '}
                  {APP_TERMS_OF_USE ? (
                    <>
                      <a href={APP_TERMS_OF_USE} target="_blank">
                        {t('Terms of use')}
                      </a>
                      {' | '}
                    </>
                  ) : null}
                  {MAP_SHOW_DISCLAIMER ? (
                    <>
                      <a
                        onClick={() => {
                          setShowDisclaimer(true);
                        }}
                      >
                        {t('Map Disclaimer')}
                      </a>
                      {' | '}
                    </>
                  ) : null}
                  {COMPANY_ABOUT_URL ? (
                    <a href={COMPANY_ABOUT_URL} target="_blank">
                      {t('About')}
                    </a>
                  ) : null}
                  <Spinner
                    size="pico"
                    color="secondary"
                    className={`${classes.spinner} map-load-indicator`}
                  />
                </div>
              </div>
            );
          }}
        </Map>

        <Legend popup={popup} layerGroups={layerGroups} />

        <MapControls>
          <CustomMapControls />
          <div>
            <RecenterControl onClick={this.onRecenterChange} />
            <ZoomControl viewport={viewport} onClick={this.onZoomChange} />
          </div>
        </MapControls>
      </div>
    );
  }

  private handleLoadIndicator(e) {
    document
      .querySelector('.map-load-indicator')
      ?.classList?.[e.type === 'idle' ? 'add' : 'remove']('hidden');

    // hide redundant links, have to do this after baselayer load, can't do nth-of-type with subselector in pure css
    document.querySelectorAll('a[title="Mapbox"]').forEach((elem, key) => {
      if (key > 0) {
        elem.classList.add('hidden');
      }
    });
  }
}

// TODO Remove UserMenuWrapper after refactoring MapComponent to be a functional component
// This only exists to make use of 'useContext()' inside of it
function UserMenuWrapper(props) {
  const { selected } = props;
  const { logout, login, isAuthenticated, userData } = useContext(Auth0Context);

  return (
    <UserMenu
      selected={selected}
      isAuthenticated={isAuthenticated}
      userData={userData}
      profileLinkProps={{
        component: Link,
        to: { type: 'PROFILE' },
      }}
      onLogin={() => {
        trackGtagEvent('login');
        login();
      }}
      onLogout={logout}
      onSignUp={() => {
        trackGtagEvent('sign_up');
        login({ initialScreen: 'signUp' });
      }}
      onSubmitFeedback={async (values) => {
        return ProfileService.sendFeedback(values);
      }}
    />
  );
}

export default withStyles(styles)(MapComponent);
