/*
  Copyright 2018-2020 National Geographic Society

  Use of this software does not constitute endorsement by National Geographic
  Society (NGS). The NGS name and NGS logo may not be used for any purpose without
  written permission from NGS.

  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import debounce from 'lodash/debounce';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trackGtagEvent } from 'utils';

import { QUERIES, SearchInput, useInitialPaginationFetch } from '@marapp/earth-shared';

import { useAuth0 } from '../../auth/auth0';
import BackToLocation from '../../components/back-to-location';
import FilterBy from '../../components/filter-by';
import InfiniteList from '../../components/infinite-list';
import ListItem from '../../components/list-item';
import SidebarLayoutSearch from '../../components/sidebar/sidebar-layout-search';
import { useLayers } from '../../fetchers';
import { ILayer } from '../../modules/layers/model';
import { EPanels } from '../../modules/sidebar/model';

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    backgroundColor: theme.palette.grey['600'],
  },
}));

interface IProps {
  selected: boolean;
  children: any;
  basemap?: string;
  basemaps?: Array<{
    slug: string;
    name: string;
    background: string;
    id: string;
  }>;
  nextPageCursor?: string;
  setMapStyle?: (slug: any) => void;
  mapStyle?: any;
  toggleLayer?: (any) => void;
  layers?: {
    loading?: boolean;
    search?: any;
    active?: string[];
    listActive?: any[];
    results?: any[];
    nextPageCursor?: string;
  };
  open?: boolean;
  layersPanel?: boolean;
  preview?: ILayer;
  panel?: string;
  panelExpanded?: boolean;
  locationName?: string;
  locationOrganization?: string;

  setSidebarPanel?: (value: any) => void;
  setSidebarPanelExpanded?: (value: boolean) => void;
  setLayersSearch?: (value: any) => void;
  setPlacesSearch?: (value: any) => void;
  setPreview?: (value: any) => void;
  resetLayersActive?: () => void;
  nextLayersPage?: (value: any) => void;
  setLayersSearchOpen?: (value: boolean) => void;
}

const Layers = (props: IProps) => {
  const {
    selected,
    children,
    layers,
    preview,
    panelExpanded,
    setPreview,
    setSidebarPanel,
    setSidebarPanelExpanded,
    locationName,
    locationOrganization,
    setLayersSearch,
    setPlacesSearch,
    setLayersSearchOpen,
  } = props;
  const { search, active } = layers;
  const showX = !!search.search;
  const showFilter = !selected || panelExpanded;
  const showBack = selected && panelExpanded;
  const classes = useStyles();
  const { t } = useTranslation();
  const [availableFilters, setAvailableFilters] = useState(null);
  const { selectedGroup } = useAuth0();
  const fetchedLayers = useLayers(QUERIES.LAYER.getPrimaryFiltered(search.search, search.filters));
  const { data: layersData, awaitMore, nextPage, isValidating } = fetchedLayers;

  const { totalResults } = useInitialPaginationFetch(search, fetchedLayers, (newFetch) => {
    setAvailableFilters(newFetch?.meta?.filters);
  });

  const handleChange = (newValue) => {
    setLayersSearch({ search: newValue });
  };

  const handleBack = () => {
    if (selected) {
      setPlacesSearch({ search: locationName });
      setSidebarPanel(EPanels.PLACES);
    }
    setSidebarPanelExpanded(false);
  };

  const handleReset = () => {
    setLayersSearch({
      search: '',
      filters: {},
      open: false,
    });
  };

  const onToggleLayer = (layer) => {
    const { toggleLayer } = props;

    const isCurrentlyActive = !!active.find((slug) => slug === layer.slug);
    if (!isCurrentlyActive) {
      trackGtagEvent('select_content', { content_type: 'layer', item_id: layer.slug });
    }

    toggleLayer(layer);
  };

  return (
    <SidebarLayoutSearch
      fixedContent={
        <>
          <Box p={2} className={classes.searchContainer}>
            <SearchInput
              value={search.search}
              placeholder={t('search layers')}
              onChange={handleChange}
              onReset={handleReset}
              onFocus={() => setSidebarPanelExpanded(true)}
              showResetButton={showX}
            />
          </Box>

          {showFilter && (
            <FilterBy
              type="Layer"
              open={search.open}
              onOpenToggle={setLayersSearchOpen}
              onChange={setLayersSearch}
              filters={search.filters}
              availableFilters={availableFilters || {}}
            />
          )}
          {showBack && (
            <BackToLocation
              onClick={handleBack}
              location={locationName}
              organization={locationOrganization}
            />
          )}
        </>
      }
    >
      {children && (
        <Box mt={1}>
          <Paper className="marapp-qa-other" square={true}>
            {children}
          </Paper>
        </Box>
      )}

      <Box>
        {(!selected || panelExpanded) && (
          <Paper className="marapp-qa-other" square={true}>
            {preview && (
              <div className="marapp-qa-preview">
                <Box p={2} pb={0}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="subtitle1" color="textSecondary">
                      Preview Items
                    </Typography>
                  </Box>
                </Box>
                <Box style={{ listStyle: 'none' }}>
                  <ListItem
                    title={preview.name}
                    noWrap={false}
                    flipBySlash={true}
                    active={true}
                    key={preview.slug}
                    onClick={() => {
                      setPreview(false);
                    }}
                    labels={['Preview']}
                  />
                </Box>
              </div>
            )}
            <InfiniteList
              title={t('Layers')}
              data={layersData}
              totalResults={totalResults}
              search={search.search}
              isValidating={isValidating}
              onNextPage={nextPage}
              awaitMore={awaitMore}
            >
              {(layer) => (
                <ListItem
                  hint={layer.$searchHint}
                  title={layer.name}
                  noWrap={false}
                  flipBySlash={true}
                  active={!!active.find((slug) => slug === layer.slug)}
                  key={`${layer.slug}-${layer.organization}`}
                  onClick={debounce(() => onToggleLayer(layer), 200)}
                  organization={selectedGroup.length > 1 && layer.organization}
                  labels={layer.category}
                />
              )}
            </InfiniteList>
          </Paper>
        )}
      </Box>
    </SidebarLayoutSearch>
  );
};

export default Layers;
