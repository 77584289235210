/*
  Copyright 2021-2022 Impact Observatory

  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Snackbar from '@material-ui/core/Snackbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { MAP_SIDEBAR_WIDTH } from '../../../config';

interface IMapDisclaimer {
  show: boolean;
  setShow: (any) => void;
}

const useStyles = makeStyles((theme) => ({
  snackbar: {
    top: 'calc(50% - 150px)', // the unbl disclaimer text gives height of about 300px
  },
  root: {
    alignItems: 'center',
    backgroundColor: '#FFFFFFCC',
    boxShadow: theme.shadows[2],
    [theme.breakpoints.up('sm')]: {
      marginLeft: MAP_SIDEBAR_WIDTH,
    },
    marginBottom: theme.spacing[2],
    minWidth: 600,
    '& *': {
      color: theme.palette.common.black,
    },
    '& .MuiAlertTitle-root': {
      fontWeight: 700,
    },
    '& .MuiAlert-action': {
      position: 'absolute',
      top: 6,
      right: 8,
      margin: 0,
      fontSize: 20,
    },
    '& .MuiAlert-message *': {
      fontSize: '12px',
      lineHeight: '18px',
    },
    '& .MuiAlert-message ul': {
      listStyleType: "'-'",
      marginLeft: 4,
      '& li': {
        paddingLeft: 4,
      },
    },
  },
}));

const MapDisclaimer = (props: IMapDisclaimer) => {
  const { show, setShow } = props;

  React.useEffect(() => {
    // handle escape keys
    // backported from MUI v5
    // https://github.com/mui/material-ui/pull/29850/

    if (!show) {
      return undefined;
    }
    function handleKeyDown(nativeEvent) {
      if (!nativeEvent.defaultPrevented) {
        // IE11, Edge (prior to using Bink?) use 'Esc'
        if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
          setShow(false);
        }
      }
    }
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [show]);

  const { t } = useTranslation('unbl');
  const classes = useStyles();

  return (
    <Snackbar
      open={show}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      className={'map-disclaimer ' + classes.snackbar}
      onClose={() => setShow(false)}
    >
      <Alert className={classes.root} icon={false} onClose={() => setShow(false)}>
        <AlertTitle>{t('Map Disclaimer')}</AlertTitle>
        <Typography variant="caption">
          <div dangerouslySetInnerHTML={{ __html: t('un-map-disclaimer-short-form') }} />
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default MapDisclaimer;
