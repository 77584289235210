/*
  Copyright 2018-2020 National Geographic Society

  Use of this software does not constitute endorsement by National Geographic
  Society (NGS). The NGS name and NGS logo may not be used for any purpose without
  written permission from NGS.

  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/
import ReactFullpage from '@fullpage/react-fullpage';
import loadable from '@loadable/component';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import Link from 'redux-first-router-link';
import { trackGtagEvent } from 'utils';

import { MAP_APP_NAME, MAP_FULLPAGE_LICENSE } from '../../config';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#000000',
    '& .fullpage-wrapper': {
      zIndex: theme.zIndex.modal,
      minHeight: '100vh',
    },
  },
  content: {
    left: '50%',
    padding: theme.spacing(0, 2),
    pointerEvents: 'all',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    '& *': {
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  },
  subtitle: {
    fontWeight: 400,
    letterSpacing: 9,
    marginBottom: theme.spacing(2),
    textShadow: theme.shadows[1],
  },
  title: {
    fontWeight: 400,
    letterSpacing: 16,
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('lg')]: {
      whiteSpace: 'nowrap',
    },
  },
}));

const pageStartTime = performance && performance.now();

const HomePage = ({ isLoading, isWebGL = true }) => {
  const classes = useStyles();
  let Globe;
  if (isWebGL) {
    Globe = loadable((props: any) => import('../../components/globe'));
  }

  useEffect(() => {
    if (!isLoading && performance) {
      const auth0LoadTime = performance.now();
      trackGtagEvent('timing_complete', {
        name: 'auth0_load',
        value: auth0LoadTime - pageStartTime,
      });
    }
  }, [isLoading]);

  return (
    <Box className={`${classes.root} marapp-qa-pagehome translate-content`} position="relative">
      <ReactFullpage
        licenseKey={MAP_FULLPAGE_LICENSE}
        render={() => {
          return (
            <ReactFullpage.Wrapper>
              {/* ReactFullpage requires .section children */}
              <div className="section">
                <Fade in={true} timeout={{ appear: 900 }}>
                  <div className={classes.content}>
                    <Typography variant="h6" className={classes.subtitle}>
                      {isLoading ? 'Loading' : 'Start Exploring'}
                    </Typography>
                    <Typography
                      variant="h2"
                      className={`${classes.title} translate-content-ignore`}
                    >
                      {MAP_APP_NAME}
                    </Typography>
                    <Box display="flex" justifyContent="center">
                      <Button
                        className="marapp-qa-button"
                        color="secondary"
                        component={Link}
                        size="large"
                        to="/earth"
                        variant="contained"
                        disabled={isLoading}
                      >
                        {isLoading ? <CircularProgress size={24} /> : 'Launch'}
                      </Button>
                    </Box>
                  </div>
                </Fade>
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />

      {isWebGL ? <Globe visible={!isLoading} autoRotate={true} presentationMode={true} /> : ''}
    </Box>
  );
};

export default HomePage;
