/*
  Copyright 2021-2022 Impact Observatory

  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { useEffect, useRef, useState } from 'react';
import FocusTrap from 'focus-trap-react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { push } from 'redux-first-router';
import { mutate } from 'swr';
import { Icons as VizzIcons } from 'vizzuality-components';

import { ErrorBoundary, ErrorMessages, QUERIES } from '@marapp/earth-shared';

import { useAuth0 } from '../../auth/auth0';
import { useAnalyses } from '../../fetchers';
import { ELSADetails, ELSANew, ELSAWeights } from '../../components/elsa/';
import { SimpleHeader } from '../../components/header/simple';
import LayerConfigError from '../../components/layer-config-error';
import Map from '../../components/map';
import Sidebar from '../../components/sidebar';
import { MAP_ENABLE_ELSA } from '../../config';
import { ILayer } from '../../modules/layers/model';
import { AnalysisRoutes } from '../../modules/router/model';
import { APanels, EPanels } from '../../modules/sidebar/model';
import { runAnalysis } from '../../services/AnalysisService';
import { generateCacheKey } from '../../services/base/APIBase';

const useStyles = makeStyles((theme) => {
  const minTabHeight = theme.spacing(4.5);

  return {
    root: {
      background: theme.palette.grey['900'],
      flex: 1,
      position: 'relative',
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
    },
    content: {
      position: 'relative',
      width: '100%',
      height: '100vh',
      zIndex: 0,
    },
    mapContainer: (props: any) => ({
      height: '100%',
      width: '100%',
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    tabContainer: {
      backgroundColor: theme.palette.background.paper,
    },
    tabs: {
      backgroundColor: theme.palette.background.paper,
      minHeight: minTabHeight,
      '& button': {
        minWidth: 0,
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
        minHeight: minTabHeight,
      },
    },
  };
});

interface IProps {
  page?: string;
  mapStyle?: string;
  layers?: any;
  selected?: string;
  router?: any;
  setSidebarPanel?: (payload: any) => void;
  setLatestResult?: (payload: any) => void;
  resetLocationHighlight?: () => void;
}

const { ELSA_NEW, ELSA_EDIT } = AnalysisRoutes;

const ELSAPage = (props: IProps) => {
  const { router, layers, mapStyle, setSidebarPanel, setLatestResult, resetLocationHighlight } =
    props;
  const { type } = router;
  const { i18n, t } = useTranslation('unbl');
  const mapRef: any = useRef();
  const { privateGroups, selectedGroup } = useAuth0();
  const [panel, setPanel] = useState(APanels.EDIT);
  const [isMapReloading, setMapReloading] = useState(false);
  const [mapLayer, setMapLayer] = useState<ILayer>({});
  const [serverErrors, setServerErrors] = useState(null);

  const formMethods = useForm({
    mode: 'onBlur',
    shouldUnregister: false, // set to false, so that conditionally rendered components stay in form state
    // only set default values here for user changeable options
    defaultValues: {
      analysis: '',
      organization: '',
      protect_budget: 0,
      restore_budget: 0,
      manage_budget: 0,
      green_budget: 0,
      protected: false,
      agriculture_only: false,
      forest_only: false,
      name: '',
      blm: 0,
      multipri: false,
      layers: [],
      weights: {},
    },
  });

  const theme = useTheme();
  const selectedOpen = [ELSA_NEW, ELSA_EDIT].includes(type);
  const withHeaderLayout = [ELSA_EDIT].includes(type);
  const newELSALayout = MAP_ENABLE_ELSA && [ELSA_NEW].includes(type);
  const editELSALayout = MAP_ENABLE_ELSA && [ELSA_EDIT].includes(type);
  const { data: groupsWithELSA } = useAnalyses(
    QUERIES.ANALYSIS.getAllFiltered(selectedGroup.join(','), 'ELSA')
  );
  const showELSA = MAP_ENABLE_ELSA && !!groupsWithELSA?.length;
  const classes = useStyles({ ...props });

  // Custom watcher that monitors changes in it's dependency list
  // Each time such a dependency changes, a new MapBox instance will be created
  useEffect(() => {
    setMapReloading(true);
    // List of dependencies that trigger a new instance of the map (map reload)
  }, [mapStyle, i18n.language]);

  // 'Workaround flag' - dictates a new render cycle with the new MapBox instance
  useEffect(() => {
    isMapReloading && setMapReloading(false);
  }, [isMapReloading]);

  // send data to backend as AnalysisRun
  const onSubmit = async (data) => {
    // pull fields we need to submit directly to top-level
    const {
      analysis,
      name,
      location,
      organization,
      weights,
      zone,
      layers: dataLayers,
      ...other
    } = data;

    // wrap other field values in arrays, per the ELSA API spec
    const config = Object.entries(other).reduce((p, [k, v]) => ({ ...p, [k]: [v] }), {});

    // inject chosen weight values into config.layers
    config.layers = dataLayers.map((l) => {
      // match based on weight name, as converted to slug
      const w = Object.keys(weights).filter((m) => m === l.name.toLowerCase().replaceAll(' ', '_'));
      if (w.length === 1) {
        const parsed = parseInt(weights[w[0]], 10);
        l.weight = parsed !== NaN ? parsed : null;
      }
      return l;
    });

    // keep zone as is
    config.zone = zone;

    // build post data body
    const runData = {
      analysis,
      name,
      location,
      organization,
      config,
    };

    try {
      const response = await runAnalysis(analysis, runData, {
        include: ['analysis', 'location', 'organization', 'status', 'createdAt'].join(','),
      });
      if (response.data.status === 'READY') {
        // switch sidebar to layers panel
        setSidebarPanel(EPanels.LAYERS);
        // TODO un-collapse elsa list
        resetLocationHighlight();
        // save the resulting id to redux state, so /earth can check for updates
        setLatestResult(response.data.id);
        // send mutation to swr, so we update the ELSACard list immediately
        mutate(generateCacheKey(`/analysis/${analysis}`, {}));
        // redirect user to keep exploring
        push('/earth');
      } else {
        setServerErrors(response?.data.errors || [{ detail: t('Unable to start ELSA analysis') }]);
        console.error('ELSA server error', response);
      }
    } catch (error) {
      if (error && error.data) {
        setServerErrors(error?.data.errors);
      } else {
        setServerErrors([{ detail: t('Unable to submit ELSA analysis') }]);
      }
      console.error('ELSA submit error', error);
    }
  };

  return (
    <main className={`${classes.root} marapp-qa-elsa marapp-qa-pageelsa`} role="main">
      <FocusTrap>
        <FormProvider {...formMethods}>
          <form id="elsa-form" onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Sidebar selectedOpen={selectedOpen} showSidebarToggle={false}>
              {withHeaderLayout && (
                <>
                  <VizzIcons />

                  <SimpleHeader
                    title={newELSALayout ? t('Create a New ELSA Map') : t('Edit ELSA Map')}
                  />

                  <Box p={2} className={classes.tabContainer} mt={1}>
                    <Tabs
                      className={classes.tabs}
                      textColor="primary"
                      value={panel}
                      onChange={(_, newValue) => setPanel(newValue)}
                    >
                      <Tab
                        label={t('Modify Map Settings')}
                        value={APanels.EDIT}
                        className="marapp-qa-elsa-edit-tab"
                      />
                      <Tab
                        label={t('View Input Layers')}
                        value={APanels.PREVIEW}
                        className="marapp-qa-elsa-preview-tab"
                      />
                    </Tabs>
                  </Box>

                  <ELSADetails panel={panel} router={router} setMapLayer={setMapLayer} />
                </>
              )}

              {newELSALayout && (
                <ELSANew
                  privateGroups={privateGroups}
                  group={selectedGroup}
                  router={router}
                  setMapLayer={setMapLayer}
                />
              )}
            </Sidebar>

            {editELSALayout && panel === APanels.EDIT && (
              <ELSAWeights router={router} container={mapRef.current} />
            )}
          </form>
        </FormProvider>
      </FocusTrap>

      <ErrorMessages errors={serverErrors} onClose={() => setServerErrors(null)} />

      <div className={classes.content} ref={mapRef}>
        <div className={classes.mapContainer}>
          <ErrorBoundary fallbackComponent={<LayerConfigError selectedOpen={selectedOpen} />}>
            {!isMapReloading && (
              <Map
                page={props.page}
                selectedOpen={selectedOpen}
                t={t}
                activeLayers={[mapLayer]}
                showDisclaimerDefault={false}
              />
            )}
          </ErrorBoundary>
        </div>
      </div>
    </main>
  );
};

export default ELSAPage;
