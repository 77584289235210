/*
  Copyright 2018-2020 National Geographic Society
  Copyright 2022 Impact Observatory

  Use of this software does not constitute endorsement by National Geographic
  Society (NGS). The NGS name and NGS logo may not be used for any purpose without
  written permission from NGS.

  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Switch from '@material-ui/core/Switch';
import { noop } from 'lodash';
import IconAlert from 'mdi-material-ui/AlertOutline';
import IconCircleSmall from 'mdi-material-ui/CircleSmall';
import React from 'react';
import { stripHtml } from 'string-strip-html';
import Link from 'redux-first-router-link';

import { ListItem as SharedListItem } from '@marapp/earth-shared';

import { parseHintBold, slashFlip } from '../../utils';

interface IProps {
  title: string;
  key?: string;
  active?: boolean;
  linkTo?: {
    type: string;
    payload?: any;
  };
  organization?: string;
  hint?: {
    name?: string;
    description?: string;
  };
  list?: any[];
  labels?: string[];
  showProgress?: boolean;
  showFailure?: boolean;
  noWrap?: boolean;
  flipBySlash?: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  iconScale: {
    height: '0.5em',
    transform: 'scale(2)',
  },
}));

const ListItem = (props: IProps) => {
  const {
    title,
    hint,
    labels,
    organization,
    linkTo,
    onClick = noop,
    active,
    showProgress,
    showFailure,
    noWrap = true,
    flipBySlash = false,
  } = props;
  const showToggle = typeof active !== 'undefined';
  const classes = useStyles();

  const listItemProps: any = {
    onClick,
    noWrap,
    ...(linkTo &&
      linkTo.type !== 'EXTERNAL' && {
        linkTo,
        linkComponent: Link,
      }),
    ...(showToggle && {
      secondaryAction: <Switch checked={active} onClick={onClick} />,
    }),
    ...(showProgress && {
      secondaryAction: <CircularProgress onClick={onClick} size={20} />,
    }),
    ...(showFailure && {
      secondaryAction: linkTo ? (
        linkTo.type === 'EXTERNAL' && (
          <a href={linkTo.payload}>
            <IconAlert />
          </a>
        )
      ) : (
        <IconAlert />
      ),
    }),
  };

  return (
    <SharedListItem
      primary={parseHintBold(hint?.name || title)}
      secondary={
        <span>
          {organization && (
            <span key={`${organization}`}>
              {organization}
              {labels?.length > 0 && <IconCircleSmall className={classes.iconScale} />}
            </span>
          )}

          {labels?.length > 0 && (
            <span>{labels.map((l) => (flipBySlash ? slashFlip(l) : l)).join(', ')}</span>
          )}

          {hint?.description && <div>{parseHintBold(stripHtml(hint.description).result)}</div>}
        </span>
      }
      {...listItemProps}
    />
  );
};

export default ListItem;
