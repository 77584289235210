/*
  Copyright 2018-2020 National Geographic Society

  Use of this software does not constitute endorsement by National Geographic
  Society (NGS). The NGS name and NGS logo may not be used for any purpose without
  written permission from NGS.

  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorTemplate } from '@marapp/earth-shared';

import { useAuth0 } from '../../auth/auth0';

const Unauthorized = () => {
  const { logout } = useAuth0();
  const { t } = useTranslation(['translation', 'admin']);

  return (
    <ErrorTemplate
      type="403"
      message={t(
        'In order to access the app, you need to be added to at least one workspace, or you are trying to access a resource you do not have permission for.'
      )}
    >
      <Box display="flex" justifyContent="center" mt={4}>
        <Box width={168}>
          <Button
            onClick={logout}
            className="marapp-qa-button"
            color="primary"
            variant="outlined"
            size="large"
            fullWidth={true}
          >
            {t('Sign Out')}
          </Button>
        </Box>
      </Box>
    </ErrorTemplate>
  );
};

export default Unauthorized;
