/*
  Copyright 2018-2020 National Geographic Society
  Copyright 2021-2022 Impact Observatory

  Use of this software does not constitute endorsement by National Geographic
  Society (NGS). The NGS name and NGS logo may not be used for any purpose without
  written permission from NGS.

  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import concat from 'lodash/concat';
import debounce from 'lodash/debounce';
import IconChevronDown from 'mdi-material-ui/ChevronDown';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'redux-first-router-link';
import { trackGtagEvent } from 'utils';

import { getGenericDate, IResponseMany, useInitialPaginationFetch } from '@marapp/earth-shared';

import { AnalysisResultStatusEnum } from '../../../modules/analysis/model';
import { ILayer } from '../../../modules/layers/model';
import { EarthRoutes } from '../../../modules/router/model';
import InfiniteList from '../../infinite-list';
import ListItem from '../../list-item';
import { getExternalURL, PreviewELSAGroup } from '../utils';

const { ELSA_NEW } = EarthRoutes;

const useStyles = makeStyles((theme) => ({
  accordion: {
    '&$expanded': {
      margin: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  details: {
    padding: 0,
    flexDirection: 'column',
  },
  expanded: {},
  summaryExpanded: {
    minHeight: '0 !important',
    '& >div:first-child': {
      marginTop: '12px !important',
      marginBottom: '12px !important',
    },
  },
  cardEditButton: {
    position: 'absolute',
    right: theme.spacing(6),
    top: theme.spacing(1.5),
  },
}));

interface IProps {
  canCreate: boolean;
  group: string[];
  fetchedResults: IResponseMany;
  activeLayers: string[];
  resetMap?: (payload?: any) => void;
  setLayersActive?: (payload?: any) => void;
  setLocationHighlight?: (payload?: any) => void;
  resetLocationHighlight?: () => void;
  setMapBounds?: (payload?: any) => void;
  setMapLayer?: (layer?: ILayer) => void;
}

export const ELSACard = (props: IProps) => {
  const {
    canCreate,
    group,
    fetchedResults,
    activeLayers,
    setMapLayer,
    setMapBounds,
    setLayersActive,
    setLocationHighlight,
    resetLocationHighlight,
  } = props;
  const { data: resultsData, awaitMore, nextPage, isValidating, pageSize } = fetchedResults;

  const { totalResults } = useInitialPaginationFetch('', fetchedResults);
  const { t } = useTranslation('unbl');
  const hasData = !!resultsData?.length;
  const classes = useStyles();

  // create list of ELSA layer slugs, so we can turn them all off with the layer toggle
  const [ELSALayers, setELSALayers] = useState([]);
  useEffect(() => {
    if (resultsData) {
      resultsData.forEach((m) => {
        if (ELSALayers.indexOf(m.slug) === -1) {
          setELSALayers((oldLayers) => [...oldLayers, m.slug]);
        }
      });
    }
  }, [resultsData]);

  const renderELSAListItem = (elsa_map) => {
    const { slug, name, location, task, organization, updatedAt, status, result } = elsa_map;

    // @ts-ignore
    const resultAge = (new Date() - new Date(updatedAt)) / (1000 * 60); // in minutes
    let description = getGenericDate(updatedAt);
    if (status === AnalysisResultStatusEnum.READY || status === AnalysisResultStatusEnum.STARTED) {
      description = t(
        'Your new map is being optimized. You will be notified once it is ready to be viewed.'
      );
    }
    if (status === AnalysisResultStatusEnum.ERROR) {
      description = t('There was an error optimizing your map.');
    }
    if (status === AnalysisResultStatusEnum.STARTED && resultAge > 60) {
      description = t('Your map optimization has taken more than 60 minutes.');
    }
    const isCurrentlyActive = !!activeLayers.find((s) => s === slug);
    const activeNotELSA = activeLayers.filter((s) => ELSALayers.indexOf(s) === -1);

    return (
      <ListItem
        title={name}
        key={`${slug}-${organization}`}
        labels={[description]}
        active={status === AnalysisResultStatusEnum.COMPLETE ? isCurrentlyActive : undefined}
        onClick={debounce(() => {
          if (status === AnalysisResultStatusEnum.COMPLETE && result?.output) {
            const resultsGroup = PreviewELSAGroup(task, name, slug, result.output);
            if (result.summary) {
              resultsGroup.description = concat(result.output, result.summary)
                .map((s) => `<a href="${getExternalURL(s.file_path, task)}" download>${s.name}</a>`)
                .join('<br/>');
              // this needs to be a string, which gets rendered by LayerComponent as html.
            }

            // send group back up to map component
            setMapLayer(resultsGroup);

            // if it's going to be on
            if (!isCurrentlyActive) {
              // zoom to location bounds and highlight
              if (location) {
                setMapBounds({ bbox: location.bbox2d });
                setLocationHighlight({
                  id: location.id,
                  geojson: location.geojson,
                });
              }
              // turn it on
              setLayersActive(activeNotELSA.concat(slug));

              // track event
              trackGtagEvent('select_content', { content_type: 'elsa', item_id: slug });
            } else {
              // it's going to be off
              // remove all ELSALayers
              setLayersActive(activeNotELSA);
              //remove location highlight
              resetLocationHighlight();
              // but maintain map bounds and zoom
              // resetMap();
            }
          }
        }, 200)}
        showProgress={status === AnalysisResultStatusEnum.STARTED}
        showFailure={
          status === AnalysisResultStatusEnum.ERROR ||
          (status === AnalysisResultStatusEnum.STARTED && resultAge > 60)
        }
        linkTo={
          AnalysisResultStatusEnum.ERROR && {
            type: 'EXTERNAL',
            payload: getExternalURL('stderr.txt', task),
          }
        }
        noWrap={false}
      />
    );
  };

  if (hasData) {
    return (
      <Box mb={1} position="relative">
        <Paper className="marapp-qa-elsa-maps" square={true}>
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
          >
            <AccordionSummary
              expandIcon={<IconChevronDown className="marapp-qa-elsaarrow" />}
              classes={{
                expanded: classes.summaryExpanded,
              }}
            >
              <Typography variant="subtitle2" color="textSecondary">
                {t('ELSA Maps')}&nbsp;
                {!isValidating && <em>({totalResults})</em>}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              {canCreate && (
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  size="small"
                  to={{ type: ELSA_NEW }}
                  className={`${classes.cardEditButton} marapp-qa-create-elsa-map`}
                >
                  {t('ELSA New')}
                </Button>
              )}

              <InfiniteList
                data={resultsData}
                pageSize={pageSize}
                totalResults={totalResults}
                search={''}
                isValidating={isValidating}
                onNextPage={nextPage}
                awaitMore={awaitMore}
              >
                {renderELSAListItem}
              </InfiniteList>
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Box>
    );
  }

  return canCreate ? (
    <Box mb={1}>
      <Paper className="marapp-qa-elsa-maps" square={true}>
        <Box p={2} pb={0}>
          <Typography variant="subtitle2" color="textSecondary">
            {t('ELSA Maps')}
          </Typography>
        </Box>
        <Box p={2} pt={1}>
          <Typography paragraph={true}>
            {t('You currently do not have any ELSA maps available')}.&nbsp;
            {t(
              'Create a map to identify areas where nature-based actions can support achievement of national priorities related to biodiversity, climate change, and human well-being.'
            )}
          </Typography>
          <Button
            className="marapp-qa-create-collection"
            variant="outlined"
            size="large"
            component={Link}
            to={{ type: ELSA_NEW }}
          >
            {t('ELSA New')}
          </Button>
        </Box>
      </Paper>
    </Box>
  ) : (
    <Box mb={1} position="relative">
      <Paper className="marapp-qa-other" square={true}>
        <Box p={2} pb={0}>
          <Typography variant="subtitle2" color="textSecondary">
            {t('ELSA Maps')}
          </Typography>
        </Box>

        <Box p={2} pt={1}>
          <Typography paragraph={true}>
            {t(`You don't have rights to create a new ELSA Map`)}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};
