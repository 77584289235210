/*
  Copyright 2018-2020 National Geographic Society

  Use of this software does not constitute endorsement by National Geographic
  Society (NGS). The NGS name and NGS logo may not be used for any purpose without
  written permission from NGS.

  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Snackbar from '@material-ui/core/Snackbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MAP_SIDEBAR_WIDTH } from '../../../config';

interface IZoomControlPopupProps {
  onZoomChange: (zoom: number) => void;
  viewport?: { zoom: any };
  layers?: any[];
  sidebarOpened?: boolean;
}

const zoomInComparator = (layer, currentZoomLevel) => {
  return layer?.source?.minzoom && currentZoomLevel < layer?.source?.minzoom;
};

const zoomOutComparator = (layer, currentZoomLevel) => {
  return layer?.source?.maxzoom && currentZoomLevel > layer?.source?.maxzoom;
};

const filterLayersByZoomLevel = (layers, currentZoomLevel, comparator) => {
  return layers?.filter((layer) => comparator(layer, currentZoomLevel));
};

const ZoomControlPopup = (props: IZoomControlPopupProps) => {
  const {
    onZoomChange,
    layers,
    viewport: { zoom },
    sidebarOpened,
  } = props;
  const [minZoomLevel, setMinZoomLevel] = useState(null);
  const [maxZoomLevel, setMaxZoomLevel] = useState(null);
  const { t } = useTranslation();

  const useStyles = makeStyles((theme) => ({
    root: {
      alignItems: 'center',
      backgroundColor: theme.palette.grey['600'],
      boxShadow: theme.shadows[2],
      marginTop: theme.spacing(1.5),
      [theme.breakpoints.up('sm')]: {
        marginLeft: sidebarOpened ? MAP_SIDEBAR_WIDTH : 0,
      },
      '& *': {
        color: theme.palette.text.secondary,
      },
      '& span': {
        color: theme.palette.text.primary,
        cursor: 'pointer',
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    const zoomInLayers = filterLayersByZoomLevel(layers, zoom, zoomInComparator);
    const zoomOutLayers = filterLayersByZoomLevel(layers, zoom, zoomOutComparator);
    const minZoomInfo = maxBy(zoomInLayers, (l) => l?.source?.minzoom);
    const maxZoomInfo = minBy(zoomOutLayers, (l) => l?.source?.maxzoom);
    const minZoom = minZoomInfo?.source?.minzoom || null;
    const maxZoom = maxZoomInfo?.source?.maxzoom || null;
    setMinZoomLevel(minZoom);
    setMaxZoomLevel(maxZoom);
  }, [JSON.stringify(layers), zoom]);

  return minZoomLevel || maxZoomLevel ? (
    <Snackbar
      open={true}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      style={{ width: '100%' }}
    >
      <Alert severity="info" className={classes.root}>
        <Typography>
          {maxZoomLevel && <div>{t('At maximum resolution')}</div>}
          <span onClick={() => onZoomChange(minZoomLevel || maxZoomLevel)}>
            {t(minZoomLevel ? 'Zoom in' : maxZoomLevel ? 'Zoom out' : '')}
            &nbsp;{t('to see layer(s)')}
          </span>
        </Typography>
      </Alert>
    </Snackbar>
  ) : null;
};

export default ZoomControlPopup;
