/*
 * Copyright 2018-2020 National Geographic Society
 *
 * Use of this software does not constitute endorsement by National Geographic
 * Society (NGS). The NGS name and NGS logo may not be used for any purpose without
 * written permission from NGS.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * this file except in compliance with the License. You may obtain a copy of the
 * License at
 *
 *   https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed
 * under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations under the License.
 */

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import concat from 'lodash/concat';
import IconInfo from 'mdi-material-ui/InformationOutline';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';

import { ErrorBoundary, Input, QUERIES, setupErrors } from '@marapp/earth-shared';

import { useAnalysis, useLocation } from '../../fetchers';
import { resetLayers, setLayersActive } from '../../modules/layers/actions';
import { ILayer } from '../../modules/layers/model';
import { setLocationHighlight, setMapBounds } from '../../modules/map/actions';
import { IRouter } from '../../modules/router/model';
import { APanels } from '../../modules/sidebar/model';
import { ELSAManage } from './ELSAManage';
import { LayerInfoToggle, LayerSwapToggle, LayerVisibilityToggle } from './LayerToggles';
import { PreviewELSALayer } from './utils';

interface IProps {
  panel: APanels;
  router?: IRouter;
  resetLayers?: (payload: any) => void;
  setLayersActive?: (payload: any) => void;
  setLocationHighlight?: (payload: any) => void;
  setMapBounds?: (payload: any) => void;
  setMapLayer?: (layer?: ILayer) => void;
}

const useStyles = makeStyles((theme) => ({
  tab: {
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    '& .MuiTypography-subtitle1': {
      fontWeight: 400,
    },
  },
  total: {
    width: 75,
    '& hr': {
      opacity: 0.12,
    },
    '& span': {
      padding: 10,
      float: 'right',
      fontSize: 16,
      '&.error': {
        color: theme.palette.error.main,
      },
    },
  },
  layerRow: {
    borderBottom: '1px solid #444A4F',
    '&:hover': {
      backgroundColor: '#444A4F',
      '& .MuiIconButton-root': {
        opacity: 1,
      },
    },
  },
  buttonsItem: {
    alignSelf: 'center',
    '& .MuiIconButton-root': {
      marginLeft: 4,
      opacity: 0.2,
    },
    '& .icon-show-true': {
      opacity: 1,
    },
    '& .icon-show-hover': {
      opacity: 0,
    },
    // '&:hover': {
    //   '& .icon-show-hover': {
    //     visibility: 'visible'
    //   }
    // }
  },
  link: {
    '& a': {
      textDecoration: 'underline',
    },
  },
  tooltip: {
    background: '#616161',
    fontSize: 12,
    maxWidth: 500,
    '& ul': {
      paddingLeft: '1em',
    },
  },
}));

function getAnalysisConfig(analysis, name) {
  // helper function to pull val from config
  // with basic error handling

  if (!analysis) {
    return null;
  }

  const arr = analysis.config[name] || [];
  if (arr.length === 0) {
    console.error(`analysis.config[${name}] has no value`);
    return undefined;
  }

  if (arr.length === 1) {
    return arr[0];
  } else {
    return arr;
  }
}

function WithData(props: IProps) {
  const classes = useStyles();
  const { panel, router, setLayersActive, setMapLayer } = props;
  const { slug, organization } = router.payload;
  const { data: analysis, error } = useAnalysis(
    slug,
    QUERIES.ANALYSIS.getAllFiltered(organization)
  );
  const { data: place, revalidate } = useLocation(
    analysis?.config.location[0],
    QUERIES.LOCATION.getOneWithoutGeoJSON(organization)
  );
  const [showPreviewLayer, setShowPreviewLayer] = useState(null);
  const { control, errors, formState, setValue } = useFormContext();
  const { touched, isDirty, isValid } = formState;
  const renderErrorFor = setupErrors(errors, touched);

  let totalBudget = 0;
  const watchBudgets = useWatch({
    name: ['protect_budget', 'manage_budget', 'restore_budget', 'green_budget'],
  });

  useEffect(() => {
    totalBudget = Object.values(watchBudgets).reduce((a, b) => a + b, 0) || 0;
    setValue('total_budget', totalBudget);
  }, [watchBudgets]);

  // save some config values to the form state, for pass through to analysis
  useEffect(() => {
    if (analysis) {
      // copy required keys from config to form state
      // these are needed to run the analysis
      // should be set before adjusting weights
      const REQUIRED_KEYS = ['language', 'iso3', 'layers', 'zone'];
      REQUIRED_KEYS.forEach((k) => setValue(k, getAnalysisConfig(analysis, k)));
    }
  }, [analysis]);

  const { t } = useTranslation('unbl');

  if (!analysis) {
    return <></>;
  }

  const previewLayers = concat(analysis.config.layers, analysis.config.zone);

  return (
    <>
      <Box
        p={2}
        className={classes.tab}
        style={{ display: panel === APanels.EDIT ? 'block' : 'none' }}
      >
        <Typography variant="subtitle1">
          {t('Setting Percentage')}
          &nbsp;
          <Tooltip
            interactive={true}
            className={`marapp-qa-action-elsa-layer-swap-toggle icon-show-hover`}
            classes={{ tooltip: classes.tooltip }}
            title={
              <Box p={1}>
                {t(
                  'This part of the ELSA tool on UNBL enables you to customize the analysis based on the national context.'
                )}
                <ul>
                  <li>
                    {t(
                      'Adjust each slider to reflect existing national area-based targets for the percent of land to protect, manage, restore, and designate for urban greening.'
                    )}
                  </li>
                  <li>
                    {t(
                      'Respond yes or no to the questions to include or exclude specific geographic areas to be considered for protection, management, or restoration in the ELSA analysis.'
                    )}
                  </li>
                </ul>
                <span
                  className={classes.link}
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'View the rapid ELSA analysis user guide for more information on configuring parameters.',
                      {
                        link: 'https://unbiodiversitylab.org/rapid-elsa-analysis-user-guide/',
                      }
                    ),
                  }}
                />
                {t('')}
              </Box>
            }
            placement="bottom-start"
          >
            <IconInfo fontSize="small" />
          </Tooltip>
        </Typography>
        <p>
          {t(
            'Select the percentage of land for the final ELSA map to allocate to each nature-based action within the country.'
          )}
        </p>

        <ErrorBoundary message={'Unable to render protected areas. Check config in admin.'}>
          <ELSAManage
            title={t('Protect')}
            subtitle={t('Include existing protected areas?')}
            budgetName={'protect_budget'}
            budgetMin={getAnalysisConfig(analysis, 'protect_min_no_lock')}
            budgetMax={getAnalysisConfig(analysis, 'protect_max')}
            budgetStart={getAnalysisConfig(analysis, 'protect_budget')}
            lockName={'protected'}
            lockStart={getAnalysisConfig(analysis, 'protected')}
            lockMin={getAnalysisConfig(analysis, 'protect_min_lock')}
          />
        </ErrorBoundary>

        <ErrorBoundary message={'Unable to render agricultural areas. Check config in admin.'}>
          <ELSAManage
            title={t('Manage')}
            subtitle={t('Consider agricultural areas only?')}
            budgetName={'manage_budget'}
            budgetMin={getAnalysisConfig(analysis, 'manage_min')}
            budgetMax={getAnalysisConfig(analysis, 'manage_max')}
            budgetStart={getAnalysisConfig(analysis, 'manage_budget')}
            lockName={'agriculture_only'}
            lockStart={getAnalysisConfig(analysis, 'agriculture_only')}
            lockMax={getAnalysisConfig(analysis, 'manage_max_ag')}
          />
        </ErrorBoundary>

        <ErrorBoundary message={'Unable to render forest ecosystems. Check config in admin.'}>
          <ELSAManage
            title={t('Restore')}
            subtitle={t('Consider forest ecosystem only?')}
            budgetName={'restore_budget'}
            budgetMin={getAnalysisConfig(analysis, 'restore_min')}
            budgetMax={getAnalysisConfig(analysis, 'restore_max')}
            budgetStart={getAnalysisConfig(analysis, 'restore_budget')}
            lockName={'forest_only'}
            lockStart={getAnalysisConfig(analysis, 'forest_only')}
            lockMax={getAnalysisConfig(analysis, 'restore_max_forest')}
          />
        </ErrorBoundary>

        <ErrorBoundary message={'Unable to render urban greening. Check config in admin.'}>
          {getAnalysisConfig(analysis, 'green_max') && (
            <ELSAManage
              title={t('Urban Greening')}
              budgetName={'green_budget'}
              budgetMin={getAnalysisConfig(analysis, 'green_min')}
              budgetMax={getAnalysisConfig(analysis, 'green_max')}
              budgetStart={getAnalysisConfig(analysis, 'green_budget')}
            />
          )}
        </ErrorBoundary>

        <div className={classes.total}>
          <hr />
          <Controller
            className="marapp-qa-elsa-total-budget"
            control={control}
            defaultValue={0}
            error={renderErrorFor('total_budget')}
            name="total_budget"
            render={({ value }) => (
              <NumberFormat
                className={value > 100 ? 'error' : undefined}
                customInput={Input}
                size="small"
                width={40}
                isNumericString={true}
                decimalScale={2}
                displayType="text"
                value={value}
                suffix="%"
                variant={'standard'}
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name="location"
          defaultValue={getAnalysisConfig(analysis, 'location')}
          as={<TextField type="hidden" />}
        />
      </Box>
      <Box
        style={{ overflowY: 'auto' }}
        className={classes.tab}
        style={{ display: panel === APanels.PREVIEW ? 'block' : 'none' }}
      >
        {previewLayers.map((layer, index) => {
          let previewLayer = PreviewELSALayer(
            `elsa_inputs/${layer.feature || layer.file_name}`,
            layer.name
          );
          // translate legend items here, where we have react context
          const translatedLegend = previewLayer.legendConfig['items'].map((item) => ({
            value: item.value,
            name: t(item.name),
            color: item.color,
          }));
          previewLayer.legendConfig['items'] = translatedLegend;

          return (
            <Box key={`layer-${index}`} className={classes.layerRow} p={2}>
              <Grid container={true} spacing={0}>
                <Grid item={true} xs={9}>
                  <Box>
                    <Typography>{layer.name}</Typography>
                    <Typography color="textSecondary">
                      {layer.policy_short || layer.short}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item={true} className={classes.buttonsItem} xs={3}>
                  <Box display="flex" justifyContent="flex-end">
                    {layer.swap ? (
                      <LayerSwapToggle
                        tooltipText={
                          <>
                            <Typography variant="caption">{t('National Data Swap')}</Typography>
                            <br />
                            <Typography variant="caption">
                              <span
                                className={classes.link}
                                dangerouslySetInnerHTML={{
                                  __html: t(
                                    'To swap in a national dataset for this global dataset, review the data criteria and email support@unbiodiversitylab.org',
                                    {
                                      link1:
                                        'https://unbiodiversitylab.org/rapid-elsa-analysis-national-data-criteria/',
                                      link2: 'mailto:support@unbiodiversitylab.org',
                                    }
                                  ),
                                }}
                              />
                            </Typography>
                          </>
                        }
                      />
                    ) : (
                      <></>
                    )}
                    <LayerInfoToggle
                      title={layer.name}
                      text={layer.description}
                      citation={layer.citation}
                    />
                    <LayerVisibilityToggle
                      previewLayer={previewLayer}
                      show={showPreviewLayer}
                      setShow={setShowPreviewLayer}
                      setLayersActive={setLayersActive}
                      setMapLayer={setMapLayer}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Box>
    </>
  );
}

export default connect(
  (state: any) => ({
    ...state.router.payload,
  }),
  {
    resetLayers,
    setLayersActive,
    setLocationHighlight,
    setMapBounds,
  }
)(WithData);
