import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconCircleSmall from 'mdi-material-ui/CircleSmall';
import IconInfo from 'mdi-material-ui/InformationOutline';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '../../auth/auth0';
import { ILayer } from '../../modules/layers/model';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[6],
    marginLeft: theme.spacing(3),
    maxHeight: '40vh',
    overflowY: 'auto',
  },
}));

interface IProps {
  restrictedLayers: ILayer[];
  restrictedChildLayers?: ILayer[];
}

export function LayerRestrictionWarning(props: IProps) {
  const { restrictedLayers, restrictedChildLayers } = props;
  const { selectedGroup } = useAuth0();
  const { t } = useTranslation();
  const classes = useStyles();

  return restrictedLayers?.length || restrictedChildLayers?.length ? (
    <Fade in={true}>
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        className="marapp-qa-restriced-layers-list"
        interactive={true}
        placement="bottom-start"
        title={
          <>
            {!!restrictedChildLayers?.length && (
              <div className="marapp-qa-child-layers-group">
                <Typography gutterBottom={true} variant="body2" color="textSecondary">
                  {t('Child layers')}
                </Typography>

                {restrictedChildLayers?.map((layer) => (
                  <Typography key={layer.id} gutterBottom={true} variant="body2">
                    <span>{layer.name}&nbsp;</span>
                    {selectedGroup?.length > 1 && (
                      <>
                        <IconCircleSmall /> {layer.organization}
                      </>
                    )}
                  </Typography>
                ))}

                {restrictedLayers?.length ? (
                  <>
                    <Box my={1}>
                      <Divider />
                    </Box>

                    <Typography gutterBottom={true} variant="body2" color="textSecondary">
                      {t('Additional layers')}
                    </Typography>
                  </>
                ) : null}
              </div>
            )}

            {restrictedLayers?.map((layer) => (
              <Typography key={layer.id} gutterBottom={true} variant="body2">
                {layer.name}&nbsp;
                {selectedGroup?.length > 1 && (
                  <>
                    <IconCircleSmall /> {layer.organization}
                  </>
                )}
              </Typography>
            ))}
          </>
        }
      >
        <Typography
          className="marapp-qa-restricted-layers-warning"
          variant="caption"
          color="textSecondary"
        >
          <IconInfo />
          &nbsp;
          {t('Some layers are blocked for download by copyright restrictions')}
        </Typography>
      </Tooltip>
    </Fade>
  ) : null;
}
