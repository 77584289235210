/*
 * Copyright 2018-2020 National Geographic Society
 *
 * Use of this software does not constitute endorsement by National Geographic
 * Society (NGS). The NGS name and NGS logo may not be used for any purpose without
 * written permission from NGS.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * this file except in compliance with the License. You may obtain a copy of the
 * License at
 *
 *   https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed
 * under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations under the License.
 */

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import IconClose from 'mdi-material-ui/Close';
import IconDownload from 'mdi-material-ui/Download';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Menu, MuiSelect, serializeFilters, Spinner } from '@marapp/earth-shared';

import { MAP_SIDEBAR_WIDTH_WIDE } from '../../../config';
import MetricService from '../../../services/MetricService';
import { CollectionMetricsTable } from './CollectionMetricsTable';
import { mapMetricDataToTableDataHandler, METRIC_HANDLERS } from './config';

interface IDownloadMetricData {
  metrics: string[];
  fileType: string;
}

interface IProps {
  collection: any;
  isLoadingMetricSlugs: boolean;
  metricSlugs: any[];
  onDownloadMetricData: (values: IDownloadMetricData) => void;
  sidebarOpened: boolean;
}

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  previewDialogContainer: {
    zIndex: '8 !important',
  },
  previewDialogPaper: {
    maxHeight: 'calc(100vh - 200px)',
  },
  previewDialogContent: {
    padding: theme.spacing(0, 2, 2),
  },
  previewDialogRoot: {
    zIndex: '8 !important',
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  previewDialogShifted: {
    [theme.breakpoints.up('sm')]: {
      left: `${MAP_SIDEBAR_WIDTH_WIDE}px !important`,
      '& > div': {
        left: `${MAP_SIDEBAR_WIDTH_WIDE}px !important`,
      },
    },
  },
}));

export function CollectionMetricsPreview(props: IProps) {
  const {
    collection,
    isLoadingMetricSlugs,
    metricSlugs,
    onDownloadMetricData,
    sidebarOpened,
  } = props;

  const { organization } = collection;
  const { t } = useTranslation();
  const classes = useStyles();
  const popupState = usePopupState({ variant: 'popover', popupId: 'download-actions' });
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [isCollectionMetricDataLoading, setIsCollectionMetricDataLoading] = useState(false);
  const [collectionMetricData, setCollectionMetricData] = useState(null);
  const [collectionMetricDataError, setCollectionMetricDataError] = useState('');

  const metricSlug = selectedMetric?.value;

  const fetchCollectionMetricData = async () => {
    try {
      setIsCollectionMetricDataLoading(true);
      setCollectionMetricDataError('');
      const data = await MetricService.downloadMetrics(collection.slug, {
        filter: serializeFilters({
          slug: metricSlug,
        }),
        group: organization,
        include: 'location',
        select: 'location.name',
      });

      setCollectionMetricData(mapMetricDataToTableDataHandler(metricSlug, data));
    } catch (e) {
      setCollectionMetricDataError(e?.data?.message || 'Something went wrong');
      console.log(e);
    } finally {
      setIsCollectionMetricDataLoading(false);
    }
  };

  useEffect(() => {
    if (selectedMetric) {
      fetchCollectionMetricData();
    } else {
      setCollectionMetricData(null);
    }
  }, [collection, selectedMetric]);

  const handleClosePreview = () => {
    setSelectedMetric(null);
    setCollectionMetricData(null);
  };

  const triggerDownload = (fileType: string) => {
    onDownloadMetricData({
      metrics: [metricSlug],
      fileType,
    });
  };

  const metricSelectOptions = [
    ...(metricSlugs || []).filter((slug) => Object.keys(METRIC_HANDLERS).includes(slug.value)),
  ];

  return (
    <>
      <Box mb={2}>
        <Typography color="textSecondary" variant="subtitle2" component="p">
          {t('Select a metric to compare data points of collection places')}
        </Typography>
      </Box>

      <MuiSelect
        value={selectedMetric}
        onChange={setSelectedMetric}
        options={metricSelectOptions}
        className="marapp-qa-metrics-preview-dropdown"
        label={t('Metric')}
        isLoading={isLoadingMetricSlugs}
      />

      <Dialog
        open={!!collectionMetricData}
        onClose={handleClosePreview}
        fullWidth={true}
        maxWidth="xl"
        classes={{
          paper: classes.previewDialogPaper,
          container: classes.previewDialogContainer,
          root: classNames(classes.previewDialogRoot, {
            [classes.previewDialogShifted]: !!sidebarOpened,
          }),
        }}
      >
        <DialogTitle disableTypography={true}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography className="marapp-qa-metric-name" variant="subtitle1">
                {t(metricSlug)}
              </Typography>

              {isCollectionMetricDataLoading ? <Spinner size="mini" position="relative" /> : null}
            </Box>

            <Box display="flex">
              <IconButton className="marapp-qa-actiondownload" {...bindTrigger(popupState)}>
                <IconDownload fontSize="small" />
              </IconButton>

              <Menu
                popupState={popupState}
                options={[
                  {
                    label: `${t('Download metric as a')} :`,
                    disabled: true,
                  },
                  {
                    label: 'CSV',
                    className: 'marapp-qa-download-csv',
                    onClick: () => triggerDownload('csv'),
                  },
                  {
                    label: 'JSON',
                    className: 'marapp-qa-downlaod-json',
                    onClick: () => triggerDownload('json'),
                  },
                ]}
              />

              <IconButton className="marapp-qa-actionclose" onClick={handleClosePreview}>
                <IconClose fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.previewDialogContent}>
          {isCollectionMetricDataLoading ? null : collectionMetricDataError ? (
            <Typography align="center" color="error">
              {t(collectionMetricDataError)}
            </Typography>
          ) : (
            <CollectionMetricsTable metricData={collectionMetricData} metricSlug={metricSlug} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
