/*
  Copyright 2018-2020 National Geographic Society
  Copyright 2021-2022 Impact Observatory

  Use of this software does not constitute endorsement by National Geographic
  Society (NGS). The NGS name and NGS logo may not be used for any purpose without
  written permission from NGS.

  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

export interface ILayerGroup {
  dataset: string;
  description: string;
  layers: ILayer[];
  legendType: 'yearpicker' | 'yeardatepicker';
  name: string;
  slug: string;
  visibility: boolean;
}

export interface ILayer {
  id: string;
  slug?: string;
  name?: string;
  description: string;
  type?: LayerType;
  organization?: string;
  provider?: LayerProvider;
  category: LayerCategory[];
  tags: string[];
  legendConfig: object;
  layerConfig: ILayerConfig;
  STACConfig?: ISTACConfig;
  decodeFunction?: any;
  interactionConfig: any;
  published: boolean;
  licenseRestriction: boolean;
  createdAt: Date;
  updatedAt: Date;
  version: number;
  references?: ILayer[];
  source?: any;
  paramsConfig?: any;
  sqlConfig?: any;
  decodeConfig?: any;
  timelineConfig?: any;
  render?: any;
}

export interface ILayerRaw extends ILayer {
  config: any;
}

interface ILayerConfig {
  paramsConfig?: any;
  sqlConfig?: any;
  decodeConfig?: any;
  timelineConfig?: any;
  type: LayerType;
  layers: ILayer[];
  body: any;
}

interface ISTACConfig {
  collection?: string;
  dataType?: string;
  asset?: string;
  startDate?: string;
  endDate?: string;
  STAC_API_URL?: string;
  TILER_URL?: string;
  STACLegendItems?: any[];
}

export enum LayerCategory {
  BOUNDARIES = 'Boundaries',
  ADMIN_AREAS = 'Boundaries/Administrative Areas',
  BIOGEOGRAPHIC_REGIONS = 'Boundaries/Biogeographical Regions',
  PROTECTED_CONSERVED_AREAS = 'Protected and Conserved Areas',
  LAND_COVER_AND_USE = 'Land Cover and Land Use',
  WATER = 'Water',
  WATER_FRESH = 'Water/Freshwater',
  WATER_OCEANS = 'Water/Oceans',
  CLIMATE_AND_CARBON = 'Climate and Carbon',
  CLIMATE_CHANGE_MITIGATION = 'Climate and Carbon/Climate Change Mitigation',
  CLIMATE_CHANGE_ADAPTATION = 'Climate and Carbon/Climate Change Adaptation',
  NATURE_BASED_SOLUTIONS = 'Nature Based Solutions',
  SUSTAINABLE_DEVELOPMENT = 'Sustainable Development',
  NATURAL_HAZARDS = 'Natural Hazards',
  BIODIVERSITY = 'Biodiversity',
  BIODIVERSITY_SPECIES = 'Biodiversity/Species',
  BIODIVERSITY_ECOSYSTEMS = 'Biodiversity/Ecosystem',
  BIODIVERSITY_GENES = 'Biodiversity/Genes',
  ECOSYSTEM_SERVICES = 'Ecosystem Services',
  HUMAN_IMPACT = 'Human Impact',
  AGRICULTURE = 'Agriculture',
  BUILT_ENVIRONMENT = 'Built Environment',
  RESTORATION = 'Restoration',
  SOCIETY = 'Society',
  SOCIOECONOMIC = 'Society/Socioeconomic',
  PUBLIC_HEALTH = 'Society/Public Health',
  SOCIAL_BEHAVIOR = 'Society/Social Behaviour',
  ELSA = 'ELSA',
}

export enum LayerType {
  raster = 'raster',
  vector = 'vector',
  geojson = 'geojson',
  group = 'group',
  video = 'video',
}

export enum LayerProvider {
  cartodb = 'cartodb',
  gee = 'gee',
  mapbox = 'mapbox',
  leaflet = 'leaflet',
}

export enum MapType {
  ELSA = 'ELSA',
  MLOD = 'MLOD',
}
