/*
  Copyright 2018-2020 National Geographic Society

  Use of this software does not constitute endorsement by National Geographic
  Society (NGS). The NGS name and NGS logo may not be used for any purpose without
  written permission from NGS.

  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import Typography from '@material-ui/core/Typography';
import IconChevronDown from 'mdi-material-ui/ChevronDown';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { pickBy, uniqueId } from 'lodash';

import { cleanFilters, countFilters } from '../../utils/filters';

interface IProps {
  type?: string;
  open: boolean;
  onOpenToggle: (payload?) => void;
  onChange: (payload?) => void;
  filters: { [key: string]: string[] };
  availableFilters: { [key: string]: Array<{ [key: string]: any }> };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey['600'],
    '&$expanded': {
      margin: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {},
  indented: {
    paddingLeft: theme.spacing(4),
  },
  summaryExpanded: {
    minHeight: '0 !important',
    '& >div:first-child': {
      marginTop: '12px !important',
      marginBottom: '12px !important',
    },
  },
  filterList: {
    marginBottom: '8px',
  },
}));

const FilterBy = (props: IProps) => {
  const { type, availableFilters, filters, onChange } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [currentAvailableFilters, setCurrentAvailableFilters] = useState({});
  const [currentAvailableTags, setCurrentAvailableTags] = useState([]);
  const [autocompleteKey, setAutocompleteKey] = useState('');
  const initialFilters = useRef(availableFilters);

  // Separate available filters and tags
  useEffect(() => {
    if (availableFilters) {
      setCurrentAvailableFilters(pickBy(availableFilters, (v, k) => k !== 'tags'));
      setCurrentAvailableTags(pickBy(availableFilters, (v, k) => k === 'tags')['tags']);
      if (Object.keys(initialFilters.current).length == 0) {
        initialFilters.current = availableFilters;
      }
    }
  }, [availableFilters]);

  const numberOfFilters = countFilters(filters);

  const toggleFilter = (key: string, value: string) => {
    const filterGroup = filters[key] || [];
    const exists = filterGroup.includes(value);
    // if this is a category, and it has subcategories
    // then toggling it should also toggle on all subs
    const subCategories = currentAvailableFilters[key]
      ?.filter((f) => f.primary === value)
      .map((f) => f.value);
    let newFilters = {
      [key]: exists
        ? filterGroup.filter((x) => ![value, ...subCategories].includes(x))
        : [...filterGroup, value, ...subCategories],
    };

    onChange({
      filters: cleanFilters({
        ...filters,
        ...newFilters,
      }),
    });
  };

  const clearCheckedFilters = (ev) => {
    ev && ev.stopPropagation();
    onChange({
      filters: {},
    });
    // clear the autocomplete with a hard reset of the key
    // due to bug with multiple, can't just set inputValue
    setAutocompleteKey(uniqueId('autocomplete'));
  };

  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  return (
    <Accordion
      style={{ marginTop: '-16px' }}
      classes={{
        root: classes.root,
        expanded: classes.expanded,
      }}
    >
      <AccordionSummary
        expandIcon={<IconChevronDown className="marapp-qa-filterbyarrow" />}
        classes={{
          expanded: classes.summaryExpanded,
        }}
      >
        <Typography>
          <Typography component="span" variant="button">
            {t('Filters')}
          </Typography>{' '}
          <Fade
            in={numberOfFilters > 0}
            timeout={{
              enter: theme.transitions.duration.enteringScreen,
              exit: 0, // quickly remove the button in order to hide "Clear(0)"
            }}
          >
            <Button
              className="marapp-qa-filterbyclear"
              onClick={clearCheckedFilters}
              size="small"
              variant="outlined"
              color="primary"
            >
              {t('Clear')} {`(${numberOfFilters})`}
            </Button>
          </Fade>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container={true} spacing={1}>
          {initialFilters.current['tags']?.length && (
            <Grid key={'tags'} item={true} xs={12} className={classes.filterList}>
              <Autocomplete
                multiple
                autoSelect
                blurOnSelect
                id="marapp-qa-filter-tags"
                key={autocompleteKey}
                PaperComponent={CustomPaper}
                size={'small'}
                options={currentAvailableTags || []}
                getOptionLabel={(option) => option.value}
                getOptionSelected={(option, value) => option.value === value.value}
                renderInput={(params) => (
                  <TextField {...params} variant="filled" label={t(`${type} tags`)} />
                )}
                onChange={(e: any, newValue) => {
                  // don't get change here, just full newValue
                  // so replace all of filters['tags'] with it
                  const newFilters = cleanFilters({
                    ...filters,
                    tags: newValue.map((v) => v.value),
                  });
                  onChange({
                    filters: newFilters,
                  });
                }}
              />
            </Grid>
          )}
          {Object.keys(currentAvailableFilters).map((key) => (
            <Grid item={true} key={key}>
              <Typography component="span" variant="subtitle2">
                {t(`${type} ${key}`)}
              </Typography>
              {currentAvailableFilters[key].map((filter, i) => {
                const checked = !!(filters[key] && filters[key].includes(filter.value));
                const disabled = filter.count === 0;

                return (
                  <Grid key={`${filter.key}-${filter.value}`} item={true} xs={12}>
                    <FormControlLabel
                      className={filter.isSubcategory && classes.indented}
                      label={
                        <span className="marapp-qa-filter-option">
                          {filter.label} <em>({filter.count})</em>
                        </span>
                      }
                      control={
                        <Checkbox
                          checked={checked}
                          disabled={disabled}
                          value={filter.value}
                          onChange={(e: any) => toggleFilter(key, filter.value)}
                        />
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default FilterBy;
