/*
  Copyright 2018-2020 National Geographic Society

  Use of this software does not constitute endorsement by National Geographic
  Society (NGS). The NGS name and NGS logo may not be used for any purpose without
  written permission from NGS.

  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import { format } from 'd3-format';
import { clamp } from 'lodash';
import findLast from 'lodash/findLast';
import React from 'react';

import WidgetTooltip from '../../../../components/widget/tooltip';
import { IPlace } from '../../../../modules/places/model';
import { replace } from '../../utils';
import { HumanFootprintMetric } from './model';

interface HumanFootprintConfig {
  metric: HumanFootprintMetric;
}

export const CONFIG = {
  parse: ({ metric }: HumanFootprintConfig, params, widgetConfig: any, place: IPlace) => {
    if (!metric) {
      return {
        noData: true,
        chart: [],
        template: '',
      };
    }

    const CATEGORIES = {
      no: 'no degradation (Wild)',
      0: 'low degradation',
      4: 'moderate degradation',
      8: 'high degradation',
    };

    const data = metric;
    const { sentence, legendConfig } = widgetConfig;
    const mean_2000 = Math.round(data.year_data[2000].mean);
    const mean_2013 = Math.round(data.year_data[2013].mean);

    const sentenceType = 'default';
    const change = clamp(mean_2013 - mean_2000, -1, 1);

    const change_type = {
      '-1': 'decreased',
      '0': 'was stable',
      '1': 'increased',
    }[change];

    const relative_change = {
      '-1': 'lower than',
      '0': 'equal to',
      '1': 'higher than',
    }[change];

    const change_category_key = findLast(Object.keys(CATEGORIES), (k) => {
      if (mean_2013 === 0) {
        return 'no';
      }
      // @ts-ignore
      return mean_2013 >= k;
    });
    // @ts-ignore
    const change_category = CATEGORIES[change_category_key];

    return {
      chart: legendConfig.items.map((l) => {
        return {
          x: l.label,
          color: '#E62C90',
          unit: '%',
          y: Math.round(data.year_data[l.label].mean),
        };
      }),
      template: replace(
        sentence[sentenceType],
        {
          location: place.name,
          mean_2000: format('.0f')(mean_2000),
          mean_2013: format('.0f')(mean_2013),
          change_type,
          relative_change,
          change_category,
          change_perc: format('.0f')(Math.abs(mean_2013 - mean_2000)),
        },
        {},
        {
          className: 'text-bold',
        }
      ),
      config: {
        margin: { top: 20, right: 0, left: 30, bottom: 0 },
        xKey: 'x',
        yKeys: {
          bars: {
            y: {
              maxBarSize: 100,
              itemColor: true,
            },
          },
        },
        xAxis: {
          axisLine: {
            stroke: '#CCC',
            strokeWidth: 1,
          },
        },
        yTitle: 'From Low to High',
        yAxis: {
          domain: [0, 50],
          ticks: [0, 12.5, 25, 37.5, 50],
        },
        unitFormat: (value) => {
          if (value % 1 !== 0) {
            return null;
          }
          return value;
        },
        cartesianGrid: {
          vertical: false,
          strokeDasharray: '6 6',
        },
        tooltip: {
          cursor: false,
          content: (
            <WidgetTooltip
              settings={[
                { label: 'Year:', key: 'x' },
                {
                  label: 'Score:',
                  key: 'y',
                  format: (value) => `${format('.0f')(value)}`,
                },
              ]}
            />
          ),
        },
      },
    };
  },
};

export default CONFIG;
